<div *ngIf="initialized" class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <div #myContainer id="myContainer" class="w3-margin w3-center">
    <img
      #myPhoto
      *ngIf = "!environment.getEnvValue('cardresult')"
      id    = "myPhoto"
      class = "w3-image w3-mobile photoResult"
      alt   = "photo"
      src   = "{{photo.photoResult}}"
    >
    <omnipas-cardpreview *ngIf="environment.getEnvValue('cardresult')"></omnipas-cardpreview>
  </div>
  <div class="w3-margin">
    <p
      *ngIf="router.transparams.customer_shortname == 'HVA' || router.transparams.customer_shortname == 'UVA'"
      class="warning w3-padding w3-margin-bottom"
      innerHTML="{{ ( this.deliveryaddress ? 'photo.result.deliveryaddress' : 'photo.result.attention' ) | translate }}"
    >
    </p>
    <input
      *ngIf       = "message.error"
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "back"
      value       = "{{ 'buttons.back' | translate: router.transparams }}"
      title       = "{{ 'buttons.back' | translate: router.transparams }}"
      (click)     = "back()"
   >
   <input
      *ngIf       = "environment.getEnvValue('logoutbutton') && !deliveryaddress"
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "logout"
      value       = "{{ 'buttons.logout' | translate: router.transparams }}"
      title       = "{{ 'buttons.logout' | translate: router.transparams }}"
      (click)     = "logout()"
    >
    <input
       *ngIf       = "deliveryaddress"
       type        = "button"
       class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
       name        = "continue()"
       value       = "{{ 'buttons.next' | translate: router.transparams }}"
       title       = "{{ 'buttons.next' | translate: router.transparams }}"
       (click)     = "continue()"
     >
  </div>
</div>
