<div *ngIf="initialized" class="w3-container w3-responsive w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <table id="person" class="w3-table w3-bordered w3-card w3-margin w3-reponsive">
    <col class="firstcol">
    <col>
    <thead>
      <tr><th colspan="2" class="w3-center">{{ 'cardinfo.personinfo' | translate: router.transparams }}</th></tr>
    </thead>
    <tbody class="name">
      <tr class="title"         ><th>{{ 'cardinfo.person.title'         | translate: router.transparams }}</th><td>{{ list.getValue( this.router.persondata, 'title'      , true ) }}</td></tr>
      <tr class="initials"      ><th>{{ 'cardinfo.person.initials'      | translate: router.transparams }}</th><td>{{ list.getValue( this.router.persondata, 'initials'         , true ) }}</td></tr>
      <tr class="firstname"     ><th>{{ 'cardinfo.person.firstname'     | translate: router.transparams }}</th><td>{{ list.getValue( this.router.persondata, 'girstName'        , true ) }}</td></tr>
      <tr class="middlename"    ><th>{{ 'cardinfo.person.middlename'    | translate: router.transparams }}</th><td>{{ list.getValue( this.router.persondata, 'middleName'       , true ) }}</td></tr>
      <tr class="lastname"      ><th>{{ 'cardinfo.person.lastname'      | translate: router.transparams }}</th><td>{{ list.getValue( this.router.persondata, 'lastName'         , true ) }}</td></tr>
      <tr class="nameoncard"    ><th>{{ 'cardinfo.person.nameoncard'    | translate: router.transparams }}</th><td>{{ list.getValue( this.router.persondata, 'nameOnCard'       , true ) }}</td></tr>
    </tbody>
    <tbody class="other">
      <tr class="group"         ><th>{{ 'cardinfo.person.group'         | translate: router.transparams }}</th><td>{{ list.getValue( this.router.persondata, 'cardholderProfile', true ) }}</td></tr>
      <tr class="department"    ><th>{{ 'cardinfo.person.department'    | translate: router.transparams }}</th><td>{{ list.getValue( this.router.persondata, 'departmentName'   , true ) }}</td></tr>
      <tr class="personnumber"  ><th>{{ 'cardinfo.person.personnumber'  | translate: router.transparams }}</th><td>{{ list.getValue( this.router.persondata, 'personNumber'     , true ) }}</td></tr>
      <tr class="profilenumber" ><th>{{ 'cardinfo.person.profilenumber' | translate: router.transparams }}</th><td>{{ list.getValue( this.router.persondata, 'profileNumber'    , true ) }}</td></tr>
      <tr class="origregnr" *ngIf="this.router.freefields['personfreefields'] != undefined"><th>{{ 'cardinfo.person.profilenumber' | translate: router.transparams }}</th><td>{{ list.getValue( this.router.freefields['personfreefields'], 'freefield06', false ) }}</td></tr>
    </tbody>
  </table>

  <table id="card"  class="w3-table w3-bordered w3-card w3-margin w3-reponsive">
    <col class="firstcol">
    <col>
    <thead><tr><th colspan="2" class="w3-center">{{ 'cardinfo.cardinfo' | translate: router.transparams }}</th></tr></thead>
    <tbody class="card">
      <tr class="cardnumber"    ><th>{{ 'cardinfo.card.cardnumber'      | translate: router.transparams }}</th><td>{{ this.stripCardNumber() }}</td></tr>

      <tr class="cardstatus"    >
        <th>{{ 'cardinfo.card.status'          | translate: router.transparams }}</th>
        <td *ngIf="list.getValue( this.router.persondata, 'cardID', true ) != ''">{{ 'status.description.'+(+list.getValue( this.router.persondata, 'cardStatus', true )) | translate: router.transparams }}</td>
        <td *ngIf="list.getValue( this.router.persondata, 'cardID', true ) == ''">&nbsp;</td>
      </tr>
      <tr class="startdate"     ><th>{{ 'cardinfo.card.startdate'       | translate: router.transparams }}</th><td>{{ date.datetostring( list.getValue( this.router.persondata, 'cardStartDate', true ), date.dateformat, true ) }}</td></tr>
      <tr class="enddate"       ><th>{{ 'cardinfo.card.enddate'         | translate: router.transparams }}</th><td>{{ date.datetostring( list.getValue( this.router.persondata, 'cardEndDate', true ), date.dateformat, true ) }}</td></tr>
    </tbody>
  </table>

  <div class="w3-margin">
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile w3-margin-right pageButton w3-round"
      name        = "back"
      value       = "{{ 'buttons.back' | translate: router.transparams }}"
      title       = "{{ 'buttons.back' | translate: router.transparams }}"
      (click)     = "back()"
    >
    <input
      *ngIf       =  "environment.getEnvValue('logoutbutton')"
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "logout"
      value       = "{{ 'buttons.logout' | translate: router.transparams }}"
      title       = "{{ 'buttons.logout' | translate: router.transparams }}"
      (click)     = "logout()"
    >
  </div>
</div>
