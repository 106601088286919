import { Component, OnInit, OnDestroy } from '@angular/core';
import { CardService }        from '@omnipas/services/card.service';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { ListService }        from '@omnilib/services/list.service';
import { LoadingService }     from '@omnipas/services/loading.service';
import { MessageService }     from '@omnipas/services/message.service';
import { PageHeaderService }  from '@omnipas/services/pageheader.service';
import { RouterService }      from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-activatecard',
  templateUrl: './activatecard.component.html'
})
export class ActivateCardComponent implements OnInit, OnDestroy {

  initialized: boolean = false;
  showLogout:  boolean = false;

  constructor ( public card:        CardService
              , public environment: EnvironmentService
              , public list:        ListService
              , public loading:     LoadingService
              , public message:     MessageService
              , public pageheader:  PageHeaderService
              , public router:      RouterService
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );

    this.initialized = false;

    if ( this.list.emptyList( this.router.persondata ) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader( 'pagetitle.activatecard', 'pagetext.activatecard' );
    }

    this.initialized = true;

    this.router.toggleshowwait( false );
  }

  async activatecard() {
    this.router.toggleshowwait( true );

    if ( await this.card.update(5) ) {
      this.message.setSuccessMessage('activate.success');
    } else {
      this.message.setErrorMessage('activate.failure');
    }

    this.router.toggleshowwait( false );
  }

  cancel() {
    this.router.backTo('welcome');
  }

  logout() {
    this.router.logout();
  }

  ngOnDestroy() {
    return;
  }
}
