<div *ngIf="router.transparams.customer_shortname != ''" id ="photoreqcontent" class="w3-modal-content w3-display-content w3-animate-zoom w3-theme-l5 w3-card-4">
  <header class="w3-theme-l3 w3-display-container w3-border-bottom w3-border-theme">
    <fa-icon id="icon-close" [icon]="icons.fatimes" class="w3-display-topright w3-hover-shadow close" (click)="hidePhotoRequirements()" title="{{ 'buttons.close' | translate: router.transparams }}"></fa-icon>
    <h2 class="w3-display-left w3-margin-left fullheight">{{'pagetitle.photorequirements' | translate: router.transparams }}</h2>
  </header>
  <div class="w3-container w3-margin-top" *ngIf="router.transparams.customer_shortname != 'Talland'">
    <p>{{ 'photorequirements.title' | translate: router.transparams }}</p>
    <ul>
      <li >{{ 'photorequirements.1' | translate: router.transparams }}</li>
      <li >{{ 'photorequirements.2' | translate: router.transparams }}</li>
      <li >{{ 'photorequirements.3' | translate: router.transparams }}</li>
      <li >{{ 'photorequirements.4' | translate: router.transparams }}</li>
      <li >{{ 'photorequirements.5' | translate: router.transparams }}</li>
      <li >{{ 'photorequirements.6' | translate: router.transparams }}</li>
      <li >{{ 'photorequirements.7' | translate: router.transparams }}</li>
      <li >{{ 'photorequirements.8' | translate: router.transparams }}</li>
    </ul>
    <p *ngIf="router.amount != '0000'" >{{ 'photorequirements.warning' | translate: router.transparams }}</p>
    <br/>
  </div>
  <div class="w3-container w3-margin-top" *ngIf="router.transparams.customer_shortname == 'Talland'">
    <p>{{ 'photorequirements.title' | translate: router.transparams }}</p>
    <div innerHTML="{{ 'photorequirements.text' | translate: router.transparams }}"></div>
    <br/>
  </div>
  <footer class="w3-theme-l3 w3-border-top w3-border-theme">
  </footer>
</div>
